/*! 2025-2-14 17:56:46 */
[data-v-91109f16]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.footer .el-button+.el-button[data-v-91109f16]{margin-left:30px}.ml15[data-v-91109f16]{margin-left:15px}.loc-item[data-v-91109f16]{display:flex;align-items:center}.mt20[data-v-91109f16]{margin-top:20px}.pointer[data-v-91109f16]{cursor:pointer}[data-v-91109f16] .loc-wrapper .el-input-number--small{width:200px !important}[data-v-91109f16] .el-input-number--small .el-input__inner{padding:0 10px !important;text-align:left}[data-v-91109f16] .el-input-number--small .el-input-number__increase,[data-v-91109f16] .el-input-number--small .el-input-number__decrease{display:none !important}.count[data-v-91109f16]{display:inline-block;width:80px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.el-col-6[data-v-91109f16]{display:flex;align-items:center}


.inlineFlex[data-v-08bab946] {
  display: inline-flex;
}

[data-v-07fbaa3e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-07fbaa3e]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-07fbaa3e]:hover{cursor:pointer}.green-box[data-v-07fbaa3e]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-07fbaa3e]{background:rgba(245,154,35,0.2);color:#f59a23}


/*# sourceMappingURL=chunk-24daf284.05572981.css.map*/