@import "~@/erp/styles/variables/variables.scss";




































































































































































.footer {
  .el-button+.el-button {
    margin-left: 30px;
  }
}

.ml15 {
  margin-left: 15px;
}

.loc-item {
  display: flex;
  align-items: center;
}

.mt20 {
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}

::v-deep .loc-wrapper .el-input-number--small {
  width: 200px !important;
}

::v-deep .el-input-number--small .el-input__inner {
  padding: 0 10px !important;
  text-align: left;
}

::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}

.count {
  display: inline-block;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-col-6 {
  display: flex;
  align-items: center;
}
